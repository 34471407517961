import mainjson from '../config/main';
export default function GetData() {
        return {
        "apitype":"users",
        "id":"user_id",
        "subidname":"user_name",
        "options":["view"],
        "nameSingle":"user",
        "nameMultiple":"users",
        "fields":{
            "field0":{
                "name":"ID",
                "field":"user_id",
                "type":"TextInput",
                "required":true,
                "edit":false,
                "list":true,
            },
            "field1":{
                "name":"E-mail",
                "field":"user_email",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            "field11":{
                "name":"Name",
                "field":"user_firstname",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"Type",
                "field":"user_type",
                "type":"Display",
                "list":true,
            },
            "field3":{
                "name":"Social id",
                "field":"user_resolved_external_id",
                "type":"Display",
            },
            "field3b":{
                "name":"Insert date",
                "field":"user_insert_date",
                "type":"DateInput",
                "required":true,
                "list":true
            },
            "field4":{
                "name":"Last update",
                "field":"user_update_date",
                "type":"DateInput",
                "required":true,
                "list":true
            },
            "field5":{
                "name":"Country",
                "field":"user_country",
                "type":"Display",
            },
            "field6":{
                "name":"Automatic found country",
                "field":"user_autocountry",
                "type":"Display",
            },
            "field6":{
                "name":"Ip",
                "field":"user_ip",
                "type":"Display",
                "list":false
            },
        }
    }
}